import { combineReducers } from 'redux'
import createReducer from 'lib/reducers/createReducer'
import { redirectTypes } from 'actions/types'

const INITIAL_STATE = null

function dataHandler(state, action) {
  return action.payload || INITIAL_STATE
}

const labelReducer = createReducer(INITIAL_STATE, {
  [redirectTypes.SET_REDIRECT_LABEL]: dataHandler,
})

const urlReducer = createReducer(INITIAL_STATE, {
  [redirectTypes.SET_REDIRECT_URL]: dataHandler,
})

const reducer = combineReducers({
  label: labelReducer,
  url: urlReducer,
})

export default reducer
