import React from 'react'
import PropTypes from 'prop-types'

import styles from './styles.scss'

const Grid = ({ children, row, col }) => {
  const setClasses = () => {
    let outputClass = [styles.grid]
    row && outputClass.push(styles.gridRowSpacing)
    col && outputClass.push(styles.gridColSpacing)

    return outputClass.join(' ')
  }

  return <div className={setClasses()}>{children}</div>
}

Grid.propTypes = {
  children: PropTypes.node.isRequired,
  row: PropTypes.bool,
  col: PropTypes.bool,
}

export default Grid
