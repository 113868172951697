import createReducer from 'lib/reducers/createReducer'
import { downForMaintenanceTypes } from 'lib/actions/types'

const INITIAL_STATE = false

function dataHandler(state, action) {
  return action.payload || INITIAL_STATE
}

const reducer = createReducer(INITIAL_STATE, {
  [downForMaintenanceTypes.SET_DOWN_FOR_MAINTENANCE]: dataHandler,
})

export default reducer
