import { get } from 'lib/reduxHelpers/thunks'

import { loggedInTypes } from 'lib/actions/types'

export function fetchLoggedIn() {
  return get({
    url: `${global.config.UI_PROXY_ENDPOINT}/api/logged_in`,
    baseType: loggedInTypes.FETCH_LOGGED_IN,
  })
}
