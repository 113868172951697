import createReducer from 'lib/reducers/createReducer'
import { actionTypes } from '../constants'

const INITIAL_STATE = []

function dataHandler(state, action) {
  if (action.payload) {
    return state.concat(action.payload)
  } else {
    return state.slice(1)
  }
}

const reducer = createReducer(INITIAL_STATE, {
  [actionTypes.ENQUEUE_NOTIFICATION]: dataHandler,
  [actionTypes.DEQUEUE_NOTIFICATION]: dataHandler,
})

export default reducer
