import React from 'react'
import PropTypes from 'prop-types'
import ReactModal from 'react-modal'

import styles from './styles.scss'

const NotificationModal = ({ children }) => {
  return (
    <ReactModal
      isOpen
      overlayClassName={`${styles.overlay} ${styles.showOverlay}`}
      className={styles.content}
      ariaHideApp={false}
    >
      {children}
    </ReactModal>
  )
}

NotificationModal.propTypes = {
  children: PropTypes.element.isRequired,
}

export default NotificationModal
