import React from 'react'
import PropTypes from 'prop-types'

import styles from './styles.scss'

const Gutters = ({ children, noGuttersSmall = true }) => {
  const setClasses = () => {
    let outputClass = [styles.gutters]
    noGuttersSmall && outputClass.push(styles.guttersNoGuttersSmall)

    return outputClass.join(' ')
  }

  return <div className={setClasses()}>{children}</div>
}

Gutters.propTypes = {
  children: PropTypes.node,
}

export default Gutters
