import { createSelector } from 'reselect'

const IN_PROGRESS = 'IN_PROGRESS'
const FAILURE = 'FAILURE'
const SUCCESS = 'SUCCESS'

const getRequestStatus = createSelector([requestState => requestState], requestState => {
  let status = null

  if (requestState.fetching) {
    status = IN_PROGRESS
  }
  if (requestState.fetched) {
    status = requestState.errors ? FAILURE : SUCCESS
  }

  return status
})

export { getRequestStatus, IN_PROGRESS, FAILURE, SUCCESS }
