import { combineReducers } from 'redux'
import dataReducer from 'lib/reducers/dataReducer'
import requestStateReducer from 'lib/reducers/requestStateReducer'
import { ratingTypes } from 'actions/types'

const initialState = null
const baseType = ratingTypes.FETCH_RATING

const reducer = combineReducers({
  data: dataReducer({ initialState, baseType }),
  requestState: requestStateReducer(baseType),
})

export default reducer
