import React from 'react'
import PropTypes from 'prop-types'

import styles from './styles.scss'

const DropdownOption = ({ id, onSelect, name }) => {
  const handleSelect = e => {
    e.preventDefault()
    onSelect(id)
  }

  return (
    <li key={id}>
      <a href='#' className={styles.anchor} onClick={handleSelect}>
        <span>{name}</span>
      </a>
    </li>
  )
}

DropdownOption.propTypes = {
  id: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
}

export default DropdownOption
