import React from 'react'
import PropTypes from 'prop-types'

import styles from './styles.scss'

const DropdownTrigger = ({ identifier, onToggle, active, name, enabled }) => {
  const handleToggle = e => {
    e.preventDefault()
    enabled && onToggle(identifier)
  }

  return (
    <a href='#' id={identifier} className={styles.anchor} onClick={handleToggle}>
      <span id={identifier} className={`${styles.span} ${{ [styles.active]: active }}`}>
        {name}
      </span>
    </a>
  )
}

DropdownTrigger.defaultProps = {
  enabled: false,
}

DropdownTrigger.propTypes = {
  identifier: PropTypes.string.isRequired,
  onToggle: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  enabled: PropTypes.bool,
}

export default DropdownTrigger
