import React from 'react'
import PropTypes from 'prop-types'

import ReactModal from 'react-modal'
import Button from 'lib/components/Button'

import styles from './styles.scss'

const customStyles = {
  overlay: {
    animation: 'modal-in 0.15s ease-out 0s backwards',
    backgroundColor: 'rgba(0, 30, 98, .5)',
    zIndex: '4',
  },
}

const ConfirmationModal = ({ children, showModal, onConfirm, onClose }) => {
  return (
    <ReactModal
      isOpen={showModal}
      contentLabel='Confirmation Modal'
      onRequestClose={onClose}
      className={styles.content}
      style={customStyles}
      ariaHideApp={false}
    >
      <div className={styles.textContent}>{children}</div>
      <div class={styles.buttonContent}>
        <Button className='buttonOutline' onClick={onConfirm} autoFocus>
          Yes
        </Button>
        <Button className='buttonSecondary' onClick={onClose}>
          No
        </Button>
      </div>
    </ReactModal>
  )
}

ConfirmationModal.propTypes = {
  children: PropTypes.node.isRequired,
  showModal: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default ConfirmationModal
